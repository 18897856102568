@import '~bootstrap/scss/bootstrap';

@import './assets/styles/colors.scss';
@import './assets/styles/fonts.scss';

// global
.animation-div .aos-init {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: $scrollbar-track; 
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb;  
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb-hover; 
}
// global

.App {
  font-family: $Tittilum-Web;
  font-size: 20px;
  background: $white900;
  width: 100%;

  .container {
    padding: 0;
  }
  .card {
    background: transparent;
    margin: 25px auto;
    color: $primary-theme-color-hex;
    border: none;
  }
  // .main-container {
  //   padding: 0;
  //   height: auto;
  // }
}

// image bounce onhover
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
