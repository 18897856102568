
$primary-theme-color-hex: #9000ff;
$primary-theme-color-rgb: rgb(144, 0, 255);

$white900: #ffffff;
$black900: #000000;
$link-color: #00e7ff;

$scrollbar-track: #f1f1f1;
$scrollbar-thumb-hover: #555;
$scrollbar-thumb: #888;